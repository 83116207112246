import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { CALL_TO_ACTION_DATA } from "../../../common/data/SecD3v";
import { Link } from "gatsby";
import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import CallToActionArea from "./calltoaction.style";

const CallToAction = () => {
  const { title, text, button, bubbleIcon } = CALL_TO_ACTION_DATA;
  return (
    <CallToActionArea>
      {/* {bubbleIcon.map(({ icon }, index) => (
        <div className={`callToAction-bubble-${index + 1}`} key={index}>
          <img alt="bubble-icon" key={`bubble-icon-${index}`} src={icon} />
        </div>
      ))} */}
      <Container>
        <Heading as="h3" content={title} />
        <Text as="p" content={text} />
        <Link className="registerButton" to={button?.link}>
          <span>
            {button.label}
            <Icon icon={androidArrowForward} size={16} />
          </span>
        </Link>
      </Container>
    </CallToActionArea>
  );
};

export default CallToAction;
