import React from "react";
import Container from "../common/components/UI/Container";
import Box from "../common/components/Box";
import CallToAction from "../containers/SecD3v/CallToAction";

const Register = () => {
  return (
    <>
      <CallToAction />
    </>
  );
};

export default Register;
